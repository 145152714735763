<script setup lang="ts">
import City, { CityAttrs } from '~~/utils/models/City';
import IconArrowRight from '~~/assets/svg/icons/arrow-right.svg';
import LineClip from '~~/assets/svg/lines/clip.svg';

const props = defineProps<{
  city: CityAttrs;
  lazyImage?: boolean;
  seeDetails?: boolean;
}>();
const city$ = computed(() => new City(props.city));
</script>

<template>
  <div class="h-item transition-filter duration-300 hover:grayscale-50">
    <div class="clip bg-primary p-1">
      <div class="clip relative">
        <picture class="u-photo">
          <img
            width="406"
            height="462"
            :alt="city$.data.name"
            :loading="lazyImage ? 'lazy' : 'eager'"
            :src="city$.getThumbnailURL(406, 462)"
          />
        </picture>
        <div class="absolute inset-x-24 lg:inset-x-32 bottom-24 lg:bottom-32">
          <h3 class="p-name text-24 lg:text-32 leading-32 lg:leading-43 mb-0">
            {{ city$.data.name }}
          </h3>
          <span
            class="text-14 leading-22 inline-flex gap-12 mt-4 lg:mt-16"
            v-if="seeDetails"
          >
            See Details <IconArrowRight width="26" height="24" />
          </span>
        </div>
        <RouterLink
          class="u-url stretched-link"
          :aria-label="city$.data.name"
          :to="city$.routePath"
        />
      </div>
    </div>

    <LineClip class="block -mt-1 -ml-1" />
  </div>
</template>
